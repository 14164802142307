import React from "react"
import { Grid, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  listWrapper: {
    marginTop: theme.spacing(6),
    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(10),
    },
  },
}))

const ListBlocks = ({ blocksData, columnWidth }) => {
  const classes = useStyles()
  console.log(columnWidth)
  return (
    <Grid container spacing={3} className={classes.listWrapper}>
      {blocksData.map(({ heading, list }, index) => (
        <Grid key={heading} item xs={12} md={columnWidth}>
          <Typography variant="overline" color="secondary">
            {(index + 1).toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}
          </Typography>
          <Typography variant="h6" component="h3">
            {heading}
          </Typography>
          <ul>
            {list.map(listItem => (
              <li key={listItem}>{listItem}</li>
            ))}
          </ul>

          <Grid item md={2} />
        </Grid>
      ))}
    </Grid>
  )
}

export default ListBlocks
