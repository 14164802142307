import {
  Container,
  CssBaseline,
  Box,
  CardActions,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
  Hidden,
  useMediaQuery,
} from "@material-ui/core"
import { makeStyles, ThemeProvider, useTheme } from "@material-ui/core/styles"
import classnames from "classnames"
import { navigate, graphql } from "gatsby"
import Img from "gatsby-image"
import React from "react"
import { useSelector } from "react-redux"
import { Link as ReactScroll, Element } from "react-scroll"
import Layout from "../components/Layout"
import investTheme from "../components/Layout/investTheme"
import InvestStickyCard from "../components/InvestStickyCard"
import InvestStickyNav from "../components/InvestStickyNav"
import SequenceBlocks from "../components/InvestPages/SequenceBlocks"
import ListBlocks from "../components/InvestPages/ListBlocks"
import Textfield from "../components/TextField"
import Footer from "../components/Footer"
import LocalizedLink from "../components/LocalizedLink"
import { SEO } from "../components/SEO"

const useStyles = makeStyles(theme => ({
  titleSection: {
    paddingTop: theme.spacing(7),
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(12.5),
    },
  },
  pageSubtitle: {
    fontSize: "1.5rem",
    lineHeight: "32px",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(5),
    },
  },
  sectionWrapper: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(15),
      paddingBottom: theme.spacing(15),
    },
  },
  listWrapper: {
    marginTop: theme.spacing(6),
    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(10),
    },
  },
  iconBoxTitle: {
    fontSize: "1rem",
  },
  investmentCard: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
}))

export const query = graphql`
  query($locale: String!) {
    allContentfulGoldenVisaModel(filter: { node_locale: { eq: $locale } }) {
      edges {
        node {
          pageTitle
          seoTitle
          seoDescription
          pageDescription {
            pageDescription
          }
          bannerImage {
            fluid {
              ...GatsbyContentfulFluid
            }
          }
          bannerButtonsText
          goldenVisaSectionTitle
          goldenVisaSectionCardsTitle
          goldenVisaSectionDescriptions
          workSectionTitle
          workSectionCardsTitle
          workSectionCardsDescriptions
          investmentSectionTitle
          visaServicesSectionTitle
          visaServicesSectionFirstListTitle
          visaServicesSectionFirstListItems
          visaServicesSectionSecondListTitle
          visaServicesSectionSecondListItems
          visaServicesSectionThirdListTitle
          visaServicesSectionThirdListItems

          goldenVisaSectionCardsImages {
            fluid {
              ...GatsbyContentfulFluid
            }
          }
          goldenVisaSectionDescription {
            goldenVisaSectionDescription
          }
        }
      }
    }

    allContentfulInvestPageModel(filter: { node_locale: { eq: $locale } }) {
      edges {
        node {
          querySectionTopics
          querySectionDescription {
            childMarkdownRemark {
              html
            }
          }
          querySectionImage {
            fluid {
              ...GatsbyContentfulFluid
            }
          }
          stickyCardTitle
          stickyCardServicesList
        }
      }
    }

    allContentfulGoldenVisaSimulationCalculator(
      filter: { node_locale: { eq: $locale } }
    ) {
      edges {
        node {
          investmentOptions
          investmentOptionsDescriptions
        }
      }
    }

    allContentfulLandingPageModel(filter: { node_locale: { eq: $locale } }) {
      edges {
        node {
          hateContactFormTitle
          hateContactFormSubTitle
          hateContactFormLogos {
            fluid {
              ...GatsbyContentfulFluid
            }
          }
          hateContactFormLogosId
          queryDescription {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`
const GoldenVisa = ({
  location,
  pageContext,
  data: {
    allContentfulGoldenVisaModel,
    allContentfulGoldenVisaSimulationCalculator,
    allContentfulInvestPageModel,
  },
}) => {
  const locale = useSelector(state => state?.locale?.localeObj?.locale)
  const translations = useSelector(state => state?.locale?.localeObj?.pageData)
  const classes = useStyles()
  const theme = useTheme()
  const mdUp = useMediaQuery(theme.breakpoints.up("md"))

  const {
    pageTitle,
    pageDescription,
    seoTitle,
    seoDescription,
    bannerImage,
    bannerButtonsText,
    goldenVisaSectionTitle,
    goldenVisaSectionCardsTitle,
    goldenVisaSectionDescriptions,
    workSectionTitle,
    workSectionCardsTitle,
    workSectionCardsDescriptions,
    investmentSectionTitle,
    visaServicesSectionTitle,
    visaServicesSectionFirstListTitle,
    visaServicesSectionFirstListItems,
    visaServicesSectionSecondListTitle,
    visaServicesSectionSecondListItems,
    visaServicesSectionThirdListTitle,
    visaServicesSectionThirdListItems,
    goldenVisaSectionCardsImages,
    goldenVisaSectionDescription,
  } = allContentfulGoldenVisaModel?.edges?.[0]?.node

  const {
    querySectionDescription,
    querySectionImage,
    stickyCardTitle,
  } = allContentfulInvestPageModel?.edges?.[0]?.node

  const {
    investmentOptions,
    investmentOptionsDescriptions,
  } = allContentfulGoldenVisaSimulationCalculator.edges?.[0]?.node

  const stickyCardLinks = [
    { label: translations?.header?.menu?.invest, to: "/invest" },
    { label: translations?.investPages?.whyPortugal, to: "/WhyPortugal" },
    {
      label: translations?.investPages?.realEstateOpportunities,
      to: "/RealEstateOpportunities",
    },
    {
      label: translations?.investPages?.investmentOpportunities,
      to: "/InvestmentOpportunities",
    },
  ]

  const stickyCardServicesList = [
    goldenVisaSectionTitle,
    workSectionTitle,
    investmentSectionTitle,
    visaServicesSectionTitle,
  ]

  const sections = [
    "golden-visa-section",
    "work-section",
    "investment-options-section",
    "visa-services-section",
  ]

  const visaServiceData = [
    {
      heading: visaServicesSectionFirstListTitle,
      list: visaServicesSectionFirstListItems,
    },
    {
      heading: visaServicesSectionSecondListTitle,
      list: visaServicesSectionSecondListItems,
    },
    {
      heading: visaServicesSectionThirdListTitle,
      list: visaServicesSectionThirdListItems,
    },
  ]

  return (
    <Layout
      backgroundcolor={"black"}
      Textcolor={"white"}
      selected={"invest"}
      button={"#FAD578"}
      location={location}
      pageContext={pageContext}
    >
      <SEO
        seoData={{
          title: seoTitle,
          description: seoDescription,
        }}
      />
      <div
        className={classnames(
          "golden-visa",
          locale === "zh" && "chinese-version"
        )}
      >
        <ThemeProvider theme={investTheme}>
          <CssBaseline />
          <Container>
            <Grid container>
              <Grid item md={8}>
                <Grid container className={classes.titleSection}>
                  <Grid item md={8}>
                    <Typography variant="h1">{pageTitle}</Typography>
                    <Typography
                      variant="subtitle1"
                      className={classes.pageSubtitle}
                    >
                      {pageDescription.pageDescription}
                    </Typography>
                    <Box mr={1.5} clone>
                      <Button
                        variant="contained"
                        color="secondary"
                        component={ReactScroll}
                        to="investment-options-section"
                        smooth={true}
                      >
                        {bannerButtonsText[0]}
                      </Button>
                    </Box>
                    <Button
                      variant="outlined"
                      color="primary"
                      component={LocalizedLink}
                      to="/GoldenVisaSimulation"
                    >
                      {mdUp
                        ? bannerButtonsText[1]
                        : translations?.investPages?.simulation}
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <Box mt={5}>
                      <Img fluid={bannerImage.fluid} />
                    </Box>
                  </Grid>
                </Grid>

                <Element name="golden-visa-section">
                  <Grid container className={classes.sectionWrapper}>
                    <Grid item md={8}>
                      <Typography variant="h2">
                        {goldenVisaSectionTitle}
                      </Typography>
                      <Typography variant="subtitle1">
                        {
                          goldenVisaSectionDescription.goldenVisaSectionDescription
                        }
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      container
                      spacing={3}
                      className={classes.listWrapper}
                    >
                      {goldenVisaSectionCardsTitle.map((title, index) => (
                        <Grid item xs={6} md={3} key={title}>
                          <Box width={1 / 3} pb={1.5}>
                            <Img
                              fluid={goldenVisaSectionCardsImages[index].fluid}
                            />
                          </Box>
                          <Typography
                            variant="h6"
                            className={classes.iconBoxTitle}
                          >
                            {title}
                          </Typography>
                          <Typography variant="subtitle2">
                            {goldenVisaSectionDescriptions[index]}
                          </Typography>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </Element>
                <Divider />
                <Element name="work-section">
                  <Grid container className={classes.sectionWrapper}>
                    <Grid item md={8}>
                      <Typography variant="h2">{workSectionTitle}</Typography>
                    </Grid>
                    <SequenceBlocks
                      titles={workSectionCardsTitle}
                      descriptions={workSectionCardsDescriptions}
                    />
                  </Grid>
                </Element>
                <Divider />
                <Element
                  name="investment-options-section"
                  className={classes.sectionWrapper}
                >
                  <Grid container>
                    <Grid item md={8}>
                      <Typography variant="h2">
                        {investmentSectionTitle}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} className={classes.listWrapper}>
                    {investmentOptions.map((cardTitle, index) => (
                      <Grid item key={cardTitle} md={4}>
                        <Card className={classes.investmentCard}>
                          <CardContent>
                            <Typography variant="h6">{cardTitle}</Typography>
                            <Typography>
                              {investmentOptionsDescriptions[index]}
                            </Typography>
                          </CardContent>
                          <CardActions>
                            <Button
                              variant="outlined"
                              color="primary"
                              onClick={() => {
                                navigate("/GoldenVisaSimulation", {
                                  state: { cardTitle },
                                })
                              }}
                            >
                              {translations?.investPages?.simulateCosts}
                            </Button>
                          </CardActions>
                        </Card>
                      </Grid>
                    ))}
                  </Grid>
                </Element>
                <Divider />
                <Element
                  name="visa-services-section"
                  className={classes.sectionWrapper}
                >
                  <Grid container>
                    <Grid md={8}>
                      <Typography variant="h2">
                        {visaServicesSectionTitle}
                      </Typography>
                    </Grid>
                  </Grid>
                  <ListBlocks blocksData={visaServiceData} columnWidth={4} />
                </Element>
              </Grid>
              <Hidden smDown>
                <Grid item md={4}>
                  <InvestStickyCard
                    title={stickyCardTitle}
                    servicesList={stickyCardServicesList}
                    links={stickyCardLinks}
                    sections={sections}
                    selected="/GoldenVisa"
                  />
                </Grid>
              </Hidden>
              <InvestStickyNav
                title={stickyCardTitle}
                servicesList={stickyCardServicesList}
                links={stickyCardLinks}
                sections={sections}
              />
            </Grid>
            <Divider />
          </Container>
          <Textfield
            title={translations?.investPages?.sendUsEnquiry}
            description={querySectionDescription.querySectionDescription}
            locale={pageContext.locale}
            customImage={querySectionImage}
            defaultValue="Investment"
            cardVariant="contained"
          />
          <Container>
            <Divider />
          </Container>
          <Footer hidePartners />
        </ThemeProvider>
      </div>
    </Layout>
  )
}

export default GoldenVisa
