import React from "react"
import { Grid, Typography, Box, Divider } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  listWrapper: {
    marginTop: theme.spacing(6),
    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(10),
    },
  },
  brokerItem: {
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up("md")]: {
      marginBottom: theme.spacing(3),
    },
  },
}))

const SequenceBlocks = ({ titles, descriptions }) => {
  const classes = useStyles()

  return (
    <Grid container spacing={3} className={classes.listWrapper}>
      {titles.map((title, index) => (
        <Grid key={title} item xs={12} md={4} className={classes.brokerItem}>
          <Typography variant="overline" color="secondary">
            {(index + 1).toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}
          </Typography>
          <Box pt={2} pb={2}>
            <Divider />
          </Box>
          <Typography variant="h6">{title}</Typography>
          <Typography>{descriptions[index]}</Typography>
        </Grid>
      ))}
    </Grid>
  )
}

export default SequenceBlocks
